<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="customerCollection"
          :footer-props="{
            'items-per-page-options': [50, 75, 100],
          }"
          :items-per-page="50"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClickItem"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <CustomerModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                :customerCollection="customerCollection"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></CustomerModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <template v-slot:item.phone="{ item }">
            <span v-if="item.custom_fields.phone">
              {{ item.custom_fields.phone }}
            </span>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{
              new Date(item.created_at).toLocaleString("hu-HU", {
                timeZone: "UTC",
              })
            }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { mapGetters } from "vuex";

import SiteService from "@/core/services/site.service.js";
import ApiService from "@/core/services/api.service";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import CustomerModalForm, { initialFormData } from "./CustomerModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "products.category.";

export default {
  name: "Customers",
  components: { CustomerModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("WEBSHOP.customers"),
      search: "",
      routePath: "/webShop/customers/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "id",
          value: "id",
          width: "100px",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },
        {
          text: this.$t("TABLE.phone"),
          value: "phone",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: this.$t("TABLE.created_at_date"),
          value: "created_at",
        },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      customerCollection: [],
    };
  },

  computed: {
    ...mapGetters(["statuses"]),

    endPoint() {
      return "user/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    // ...mapActions(["fetchUser"]),

    fetchModel() {
      let site_id = SiteService.getActiveSiteId();
      ApiService.get(
        `webshop/admin/${site_id}/user/${process.env.VUE_APP_CUSTOMER_ID}`
      )
        .then(({ data }) => {
          this.customerCollection = data;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    getStatus(val) {
      if (this.statuses.system && this.statuses.system.user) {
        return this.$t(
          "STATUSES." +
            this.statuses.system.user.find(
              (item) => item.key == val || (!item.key && !val)
            ).value
        );
      }
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel();
  },
};
</script>
